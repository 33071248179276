import React, { Component } from 'react';

import Slider from 'react-slick';
import '../scss/corusel/slick.scss';
import '../scss/corusel/slick-theme.scss';

// import mainPhoto from "../scss/image/Group123.png";

import '../scss/sliderMobile.scss';

export default class SimpleSlider extends Component {
  render() {
    const settings = {
      arrows: false,
      dots: true,
      // infinite: true,
      speed: window.widgetGlobalParams.options.sliderSpeed,
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: window.widgetGlobalParams.options.fade,
      initialSlide: 0,
    };

    let firstPhoto = this.props.sliderPhotosArr;
    const defaultSlideIndex = (value) => this.slider.slickGoTo(value);

    if (this.props.indexSlide === false && firstPhoto.length !== 1) {
      defaultSlideIndex(0);
    } else if (this.props.indexSlide === false && firstPhoto.length === 1) {
      defaultSlideIndex(0);
    } else if (this.props.indexSlide === true && firstPhoto.length === 1) {
      defaultSlideIndex(0);
    }

    return (
      <div className="mts_widget__MobileSliderContainer">
        <Slider ref={(slider) => (this.slider = slider)} {...settings}>
          {firstPhoto
            ? firstPhoto.map((links) => {
                return (
                  <div key={links.id - 1}>
                    <img src={`${links}`} alt=" " />
                  </div>
                );
              })
            : firstPhoto.map((links) => {
                return (
                  <div key={links.id + 1}>
                    <img src={`${links}`} alt=" " />
                  </div>
                );
              })}
        </Slider>
      </div>
    );
  }
}
