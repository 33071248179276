import Filter from './filter';
import SliderMobile from './sliderMobile';

import '../scss/rightBlock.scss';

function RightBlock(props) {
  return (
    <div className="mts__widget_info">
      <h1 className="mts_widget_title">Мультимедиа МТС Авто</h1>
      <p className="mts_widget_text">
        Система с сенсорным экраном, процессором Unisoc, СИМ-картой и встроенными сервисами МТС
      </p>
      <SliderMobile
        indexSlide={props.indexSlide}
        setSlideIndex={props.setSlideIndex}
        sliderPhotosArr={props.sliderPhotosArr}
        photoArr={props.photoArr}
        photos={props.photos}
      />
      <Filter
        indexSlide={props.indexSlide}
        setSlideIndex={props.setSlideIndex}
        changeNotActiveFilter={props.changeNotActiveFilter}
        stamp={props.stamp}
        activeStamp={props.activeStamp}
        model={props.model}
        setActiveModel={props.setActiveModel}
        year={props.year}
        activeYear={props.activeYear}
        newPhotos={props.newPhotos}
        setNewPhotos={props.setNewPhotos}
        articul={props.articul}
        photos={props.photos}
        getPhoto={props.getPhoto}
        activeFilter={props.activeFilter}
      />
    </div>
  );
}

export default RightBlock;
