import React, { useCallback, useEffect, useMemo, useState } from 'react';

import './scss/reset.css';
import './scss/App.scss';

import Slider from './components/sliderDesktop';
import RightBlock from './components/rightBlock.js';

function App() {
  const [stamp, setStamp] = useState('');
  const [model, setModel] = useState('');
  const [year, setYear] = useState('');

  const activeStamp = useCallback((value) => {
    setStamp(value);
  }, []);

  const setActiveModel = useCallback(
    (value) => {
      setModel(value);
    },
    [setModel],
  );

  const activeYear = useCallback(
    (value) => {
      setYear(value);
    },
    [setYear],
  );

  const [photos, setPhotos] = useState([]);
  const [articul, setArticul] = useState('');
  const [newPhotos, setNewPhotos] = useState([]);

  //////////При выборе в фильтре элемент заносится в "returnObj" и артикул

  let filterInfo = [];

  useEffect(() => {
    if (stamp && model && year) {
      let returnObj = {
        stamp: stamp ? stamp : '',
        model: model ? model : '',
        year: year ? year : '',
        productCode: articul ? articul : '',
      };
      window.widgetGlobalParams.options.onChangeSelect(returnObj);
      filterInfo.push(stamp, model, year);
      window.frames.info.push(filterInfo);
    }
  }, [articul]);

  const getPhoto = useCallback((value) => {
    setPhotos(value);
  }, []);

  const activeArticul = useCallback(
    (value) => {
      setArticul(value);
    },
    [articul],
  );

  let photoArr = [];
  let productCode;
  let i = 0;

  useEffect(() => {
    photos.map((arr) => {
      return arr.panels.map((element) => {
        productCode = element.productCode;
      });
    });
    activeArticul(productCode);
  });

  const [firstPhotos, setFirstPhotos] = useState([]);

  useEffect(() => {
    fetch('https://stage.auto.mts.ru/web/api/v1/products')
      .then((response) => {
        return response.json();
      })
      .then((arr) => {
        setFirstPhotos(arr);
      });
  }, []);

  let sliderPhotosArr = [];
  let sliderArr = [];

  firstPhotos.map((el) => {
    if (el !== undefined) {
      sliderArr = JSON.parse(el.pictureUrls);
      sliderPhotosArr.push(sliderArr);
    }
  });

  const [notActiveFilter, setNotActiveFilter] = useState(true);

  const changeNotActiveFilter = (value) => {
    setNotActiveFilter(value);
  };

  sliderPhotosArr = sliderPhotosArr.flat();

  if (articul !== undefined && newPhotos !== undefined && notActiveFilter !== null) {
    newPhotos.map((obj) => {
      if (obj.code === articul) {
        sliderPhotosArr = JSON.parse(obj.pictureUrls);
        photoArr = sliderPhotosArr;
      }
    });
  }

  const [indexSlide, setSliderIndex] = useState(true);

  const setSlideIndex = (value) => {
    setSliderIndex(value);
  };

  let globalWidth = document.getElementById(window.widgetGlobalParams.mainContainer).clientWidth;
  if (
    window.widgetGlobalParams.options.width <
    document.getElementById(window.widgetGlobalParams.mainContainer).clientWidth
  ) {
    globalWidth = window.widgetGlobalParams.options.width;
  }
  let globalHeight = window.widgetGlobalParams.options.height;

  //////Документ для приложения, чтобы не было конфликтов//////
  const mtsDocument = window.widgetGlobalParams.domRoot;

  ///1200
  let slickPrev = mtsDocument.querySelector('.slick-prev');
  let slickNext = mtsDocument.querySelector('.slick-next');
  let slickList = mtsDocument.querySelectorAll('.slick-list');
  let slickSlideImg = mtsDocument.querySelectorAll('.slick-slide img');

  let content = mtsDocument.querySelector('.content');
  ////890
  let mtsWidgetInfo = mtsDocument.querySelector('.mts__widget_info');
  let desktopSliderContainer = mtsDocument.querySelector('.mts_Widget__DesktopSliderContainer');
  let mobileSliderContainer = mtsDocument.querySelector('.mts_widget__MobileSliderContainer');

  let sliderDots = mtsDocument.querySelectorAll('.slick-dots');

  let filter = mtsDocument.querySelector('.mts_widget__filter');
  let title = mtsDocument.querySelector('.mts_widget_title');
  let text = mtsDocument.querySelector('.mts_widget_text');

  let shapeAplMark = mtsDocument.querySelector('.mts_wiget_shapeAplMark');
  let shapeAplModel = mtsDocument.querySelector('.mts_wiget_shapeAplModel');

  //////Медиа запросы в зависимости от ширины блока//////

  if (globalWidth <= 1249 && slickPrev != null && slickNext != null) {
    slickPrev.style.display = 'none';
    slickNext.style.display = 'none';
  }
  if (globalWidth <= 1200 && content != null) {
    content.style.padding = '2.76rem 1.17rem';
  }
  if (globalWidth <= 1200 && mtsWidgetInfo != null) {
  }
  if (globalWidth <= 1249 && shapeAplMark != null) {
    shapeAplMark.style.right = '-45px';
    shapeAplMark.classList.add('mts_wiget_shapeAplMark-new');
  }
  if (globalWidth <= 1249 && shapeAplModel != null) {
    shapeAplModel.style.right = '-45px';
    shapeAplModel.classList.add('mts_wiget_shapeAplModel-new');
  }
  if (globalWidth <= 890 && mtsWidgetInfo != null) {
    mtsWidgetInfo.style.maxWidth = '768px';
    mtsWidgetInfo.style.justifyItems = 'left';
    mtsWidgetInfo.style.minHeight = '700px';
    mtsWidgetInfo.style.height = 'auto';
  }
  if (globalWidth <= 890 && mobileSliderContainer != null) {
    mobileSliderContainer.style.display = 'flex';
  }
  if (globalWidth <= 890 && desktopSliderContainer != null) {
    desktopSliderContainer.style.display = 'none';
  }
  if (globalWidth <= 890 && sliderDots != null) {
    sliderDots.forEach((element) => {
      element.style.position = 'relative';
      element.style.bottom = '-6px';
    });
  }
  if (globalWidth <= 890 && filter != null) {
    let filterForms = filter.childNodes;
    filterForms.forEach((element) => {
      element.style.maxWidth = '768px';
    });
  }
  if (globalWidth <= 890 && title != null) {
    title.style.maxWidth = '768px';
  }
  if (globalWidth <= 890 && text != null) {
    text.style.marginBottom = '0.9rem';
  }
  if (globalWidth <= 521 && sliderDots != null) {
    sliderDots.forEach((element) => {
      element.style.bottom = '20px';
    });
  }
  if (globalWidth <= 520 && filter != null) {
    filter.style.marginTop = '0';
  }
  if (globalWidth <= 480 && filter != null) {
    filter.style.marginTop = '2.35rem';
  }
  if (globalWidth <= 480 && slickList != null) {
    slickList.forEach((element) => {
      element.style.maxHeight = '250px';
    });
  }
  if (globalWidth <= 480 && sliderDots != null) {
    sliderDots.forEach((element) => {
      element.style.bottom = '-5px';
    });
  }
  if (globalWidth <= 440 && sliderDots != null) {
    sliderDots.forEach((element) => {
      element.style.bottom = '-5px';
    });
  }
  if (globalWidth <= 440 && filter != null) {
    filter.style.marginTop = '1rem';
  }
  if (globalWidth <= 440 && filter != null) {
    filter.style.marginTop = '2.35rem';
  }
  if (globalWidth <= 430 && slickList != null) {
    slickList.forEach((element) => {
      element.style.maxHeight = '220px';
    });
  }
  if (globalWidth <= 380 && sliderDots != null) {
    sliderDots.forEach((element) => {
      element.style.bottom = '-12px';
    });
  }
  if (globalWidth <= 380 && text != null) {
    text.style.marginBottom = '24px';
  }
  if (globalWidth <= 380 && content != null) {
    content.style.padding = '40px 1.17rem 20px 1.17rem';
  }
  if (globalWidth <= 380 && slickList != null) {
    slickList.forEach((element) => {
      element.style.maxHeight = '180px';
    });
  }
  if (globalWidth <= 380 && slickSlideImg != null) {
    slickSlideImg.forEach((element) => {
      element.style.maxHeight = '180px';
    });
  }

  return (
    <div className="mainContainer" style={{ width: `${globalWidth}px`, height: `${globalHeight}` }}>
      <div className="content">
        <Slider sliderPhotosArr={sliderPhotosArr} photoArr={photoArr} indexSlide={indexSlide} />
        <RightBlock
          indexSlide={indexSlide}
          setSlideIndex={setSlideIndex}
          changeNotActiveFilter={changeNotActiveFilter}
          stamp={stamp}
          activeStamp={activeStamp}
          model={model}
          setActiveModel={setActiveModel}
          year={year}
          activeYear={activeYear}
          setNewPhotos={setNewPhotos}
          newPhotos={newPhotos}
          sliderPhotosArr={sliderPhotosArr}
          articul={articul}
          photoArr={photoArr}
          photos={photos}
          getPhoto={getPhoto}
        />
      </div>
    </div>
  );
}

export default App;
