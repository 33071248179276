import React, { useEffect, useMemo, useState, memo } from 'react';

import { Select } from 'antd';

import '../scss/filter.scss';
import '../scss/App.scss';
import 'antd/dist/antd.css';
import { useCallback } from 'react';

const { Option } = Select;

window.frames.info = [];

const Filter = React.memo((props) => {
  ///////////////Получение марок авто/////////////////

  const [brands, setBrands] = React.useState([]);

  React.useEffect(() => {
    fetch('https://stage.auto.mts.ru/web/api/v1/cars/brands')
      .then((response) => {
        return response.json();
      })
      .then((arr) => {
        setBrands(arr);
      });
  }, []);

  //////////////Получение моделей авто//////////////////

  const [models, setModels] = React.useState([]);

  const getCarModels = useCallback((id) => {
    fetch(`https://stage.auto.mts.ru/web/api/v1/cars/models?carBrandId=${id}`)
      .then((response) => {
        return response.json();
      })
      .then((arr) => {
        setModels(arr);
      });
  }, []);

  //////////////////Получение года авто///////////////////

  const [years, setYears] = React.useState([]);

  const getCarYears = useCallback((id) => {
    fetch(`https://stage.auto.mts.ru/web/api/v1/cars/panels?carModelId=${id}`)
      .then((response) => {
        return response.json();
      })
      .then((arr) => {
        setYears(arr);
      });
  }, []);

  const getNewPhotos = useCallback(() => {
    fetch(`https://stage.auto.mts.ru/web/api/v1/products`)
      .then((response) => {
        return response.json();
      })
      .then((arr) => {
        props.setNewPhotos(arr);
      });
  }, [props]);

  let nowElement;

  const [currentTitleModel, setCurrentTitleModel] = React.useState('Выбрать модель авто');
  const [currentYear, setCurrentYear] = React.useState('Год выпуска');

  /////////////Иконки для оповещения дизейбла///////////
  let iconMark = (
    <div className="mts_wiget__shape">
      <span className="mts_wiget_shapeAplMark ">Выберите марку автомобиля</span>
    </div>
  );
  let iconModel = (
    <div className="mts_wiget__shape">
      <span className="mts_wiget_shapeAplModel ">Выберите модель автомобиля</span>
    </div>
  );

  ////////////////Очистка фильтра///////////////////
  const clearYear = useCallback(() => {
    years.length = 0;
  }, [years]);

  const clearModel = useCallback(() => {
    models.length = 0;
  }, [models]);

  const onChangeStampHandler = useCallback(
    (value, name) => {
      clearModel();
      props.activeStamp(name.name);
      getCarModels(value);
      setCurrentTitleModel('Выбрать модель авто');
      setCurrentYear('Год выпуска');
      clearYear();
      props.changeNotActiveFilter(null);
      props.setSlideIndex(false);
    },
    [props.activeStamp, clearModel, clearYear, props.indexSlide],
  );

  const onChangeModelHandler = useCallback(
    (value, name) => {
      props.setActiveModel(name.children);
      getCarYears(value);
      setCurrentTitleModel(value);
      setCurrentYear('Год выпуска');
      props.changeNotActiveFilter(null);
      props.setSlideIndex(true);
    },
    [props.indexSlide],
  );

  const onChangeYearHandler = useCallback(
    (value, name) => {
      props.activeYear(name.children);
      setCurrentYear(value);
      props.getPhoto((value) => {
        for (let year of years[0]?.yearPanelsList) {
          if (name.children === year.year) {
            value = years[0]?.yearPanelsList;
          }
        }
        return value;
      });
      getNewPhotos();
      props.changeNotActiveFilter(' ');
      props.setSlideIndex(true);
    },
    [props, years, props.indexSlide],
  );

  return (
    <form className="mts_widget__filter">
      <Select
        disabled={brands.length <= 0 ? true : false}
        bordered={false}
        listHeight={160}
        defaultValue="Выбрать марку авто"
        className="mts_widget__filterSelect"
        dropdownClassName="mts_widget__list"
        onChange={onChangeStampHandler}>
        {/* Отображение марок авто */}
        {brands.map((props) => {
          return (
            <Option key={props.id} value={props.id} name={props.name}>
              {props.name}
            </Option>
          );
        })}
      </Select>

      <Select
        suffixIcon={models.length <= 0 ? iconMark : <></>}
        bordered={false}
        disabled={models.length <= 0 ? true : false}
        listHeight={160}
        defaultValue="Выбрать модель авто"
        value={currentTitleModel}
        className="mts_widget__filterSelect"
        name="model"
        onChange={onChangeModelHandler}>
        {/* Отображение моделей авто */}
        {models.map((props) => {
          return (
            <Option key={props.id} value={props.id}>
              {props.name}
            </Option>
          );
        })}
      </Select>
      <Select
        suffixIcon={years.length <= 0 ? iconModel : <></>}
        bordered={false}
        disabled={years.length <= 0 ? true : false}
        listHeight={160}
        defaultValue="Год выпуска"
        value={currentYear}
        photos={234}
        className="mts_widget__filterSelect"
        name="year"
        onChange={onChangeYearHandler}>
        {/* Отображение года */}
        {years[0]?.yearPanelsList.map((element) => {
          nowElement = element.year;
          return <Option key={element.year}>{element.year}</Option>;
        })}
      </Select>
    </form>
  );
});

export default Filter;
